const NoticeData = [
    {
        id: 1,
        img: "../img/notice-thumb-img.jpg",
        title: '2022 12월 유학간담회',
        dateCreated: '2022-11-23',
        contentTitle: '2022 12월 유학간담회',
        contentPara: '요즘 많은 유학박람회, 유학설명회가 계획 진행되고 있는 가운데 싱메이트 유학은 그동안 여러차례 학부모님과 학생들의 간담회 요청에 따라 특별한 자리를 마련하게되었습니다. 관심 있으신 분들은 참여하셔서 일방적이고 획일적인 정보를 얻는 유학 설명회나 유학 박람회 방식이 아닌 싱가포르 유학 간담회를 통해 보다 구체적이고 정확한 정보를 얻어가시고 또한 그동안 답답하셨던 부분에 대한 명쾌한 답변을 통해 효과적인 유학 계획을 세우시길 바랍니다.​'
    },
    {
        id: 2,
        img: "../img/notice-thumb-img-2.jpg",
        title: '2022 9월 유학설명회',
        dateCreated: '2022-08-21',
        contentTitle: '2022 9월 유학간담회',
        contentPara: '안녕하세요 싱메이트 유학입니다. 2022년 9월을 맞이하여 평소 싱가포르 유학에 관심을 가지셨지만 코로나 상황으로 미뤄두셨던 분들, 막연하게 생각만 하시던 분들에게 유용한 정보를 드리고자 설명회를 개최합니다. 싱가포르 현지 교육법인 대표가 직접 들려드리는 가장 정확하고 생생한 정보를 통해 유학에 대한 계획과 꿈을 구체화 시키고 실행하시는 좋은 기회 되시기를 바라며 많은 관심과 참여 부탁드립니다.'
    },
    {
        id: 3,
        img: "../img/notice-thumb-img-3.jpg",
        title: 'SIM 캠퍼스 새단장 안내',
        dateCreated: '2022-10-05',
        contentTitle: 'SIM 캠퍼스 새단장 안내',
        contentPara: '싱가포르 SIM 사립대가 리브랜딩을 하여 국내 외 학생들에게 더 나은 교육 환경을 제공하고자 새 단장 하였다는 소식입니다. 리브랜딩 행사가 쿠알라룸푸르 세인트레지스에서 열렸으며 SIM의 새로워진 아이덴티티와 제품은 말레이시아가 인더스트리 4.0으로의 전환을 실현하기 위해 인적 자원의 품질을 개발하고자 하는 시점에 시의적절합니다.'
    },
    {
        id: 4,
        img: "../img/notice-thumb-img-4.jpg",
        title: '싱메이트 대표 초청 설명회',
        dateCreated: '2022-02-20',
        contentTitle: '싱메이트 대표 초청 설명회',
        contentPara: '싱메이트 유학에서는 싱가포르 본사 대표님을 모시고 코로나 이후 변화하는 싱가포르 경제, 교육상황에 대해 보다 정확하고 현실적인 정보를 제공하고 이해를 도와 학부모님들이 혼란을 겪지 않고 유학 준비를 하실 수 있도록 설명회 자리를 마련하였습니다. 현지에서 유학상담은 물론 교육 사업을 통해 학생들의 대학 진학까지 과정을 도와 훌륭한 성과를 내고있는 저희 싱메이트를 통해 자녀들의 유학이 더욱 구체화 되고 성공적이시길 바라며 많은 참여 부탁드립니다. 감사합니다.​'
    },
    
    
]

export default NoticeData